export default () => {

  // Only used to redirect from /home to / (for Prismic preview)
  // Find the Homepage inside index.tsx

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    window.location.href = '/'
  }
  return false;
};
